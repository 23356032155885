// Material colour palette
// Based on https://material.google.com/style/color.html#color-color-palette

$material-color-amber-050:        #fff8e1 !default;
$material-color-amber-100:        #ffecb3 !default;
$material-color-amber-200:        #ffe082 !default;
$material-color-amber-300:        #ffd54f !default;
$material-color-amber-400:        #ffca28 !default;
$material-color-amber-500:        #ffc107 !default;
$material-color-amber-600:        #ffb300 !default;
$material-color-amber-700:        #ffa000 !default;
$material-color-amber-800:        #ff8f00 !default;
$material-color-amber-900:        #ff6f00 !default;
$material-color-amber-a100:       #ffe57f !default;
$material-color-amber-a200:       #ffd740 !default;
$material-color-amber-a400:       #ffc400 !default;
$material-color-amber-a700:       #ffab00 !default;

$material-color-blue-050:         #e3f2fd !default;
$material-color-blue-100:         #bbdefb !default;
$material-color-blue-200:         #90caf9 !default;
$material-color-blue-300:         #64b5f6 !default;
$material-color-blue-400:         #42a5f5 !default;
$material-color-blue-500:         #2196f3 !default;
$material-color-blue-600:         #1e88e5 !default;
$material-color-blue-700:         #1976d2 !default;
$material-color-blue-800:         #1565c0 !default;
$material-color-blue-900:         #0d47a1 !default;
$material-color-blue-a100:        #82b1ff !default;
$material-color-blue-a200:        #448aff !default;
$material-color-blue-a400:        #2979ff !default;
$material-color-blue-a700:        #2962ff !default;

$material-color-blue-grey-050:    #eceff1 !default;
$material-color-blue-grey-100:    #cfd8dc !default;
$material-color-blue-grey-200:    #b0bec5 !default;
$material-color-blue-grey-300:    #90a4ae !default;
$material-color-blue-grey-400:    #78909c !default;
$material-color-blue-grey-500:    #607d8b !default;
$material-color-blue-grey-600:    #546e7a !default;
$material-color-blue-grey-700:    #455a64 !default;
$material-color-blue-grey-800:    #37474f !default;
$material-color-blue-grey-900:    #263238 !default;

$material-color-brown-050:        #efebe9 !default;
$material-color-brown-100:        #d7ccc8 !default;
$material-color-brown-200:        #bcaaa4 !default;
$material-color-brown-300:        #a1887f !default;
$material-color-brown-400:        #8d6e63 !default;
$material-color-brown-500:        #795548 !default;
$material-color-brown-600:        #6d4c41 !default;
$material-color-brown-700:        #5d4037 !default;
$material-color-brown-800:        #4e342e !default;
$material-color-brown-900:        #3e2723 !default;

$material-color-cyan-050:         #e0f7fa !default;
$material-color-cyan-100:         #b2ebf2 !default;
$material-color-cyan-200:         #80deea !default;
$material-color-cyan-300:         #4dd0e1 !default;
$material-color-cyan-400:         #26c6da !default;
$material-color-cyan-500:         #00bcd4 !default;
$material-color-cyan-600:         #00acc1 !default;
$material-color-cyan-700:         #0097a7 !default;
$material-color-cyan-800:         #00838f !default;
$material-color-cyan-900:         #006064 !default;
$material-color-cyan-a100:        #84ffff !default;
$material-color-cyan-a200:        #18ffff !default;
$material-color-cyan-a400:        #00e5ff !default;
$material-color-cyan-a700:        #00b8d4 !default;

$material-color-deep-orange-050:  #fbe9e7 !default;
$material-color-deep-orange-100:  #ffccbc !default;
$material-color-deep-orange-200:  #ffab91 !default;
$material-color-deep-orange-300:  #ff8a65 !default;
$material-color-deep-orange-400:  #ff7043 !default;
$material-color-deep-orange-500:  #ff5722 !default;
$material-color-deep-orange-600:  #f4511e !default;
$material-color-deep-orange-700:  #e64a19 !default;
$material-color-deep-orange-800:  #d84315 !default;
$material-color-deep-orange-900:  #bf360c !default;
$material-color-deep-orange-a100: #ff9e80 !default;
$material-color-deep-orange-a200: #ff6e40 !default;
$material-color-deep-orange-a400: #ff3d00 !default;
$material-color-deep-orange-a700: #dd2c00 !default;

$material-color-deep-purple-050:  #ede7f6 !default;
$material-color-deep-purple-100:  #d1c4e9 !default;
$material-color-deep-purple-200:  #b39ddb !default;
$material-color-deep-purple-300:  #9575cd !default;
$material-color-deep-purple-400:  #7e57c2 !default;
$material-color-deep-purple-500:  #673ab7 !default;
$material-color-deep-purple-600:  #5e35b1 !default;
$material-color-deep-purple-700:  #512da8 !default;
$material-color-deep-purple-800:  #4527a0 !default;
$material-color-deep-purple-900:  #311b92 !default;
$material-color-deep-purple-a100: #b388ff !default;
$material-color-deep-purple-a200: #7c4dff !default;
$material-color-deep-purple-a400: #651fff !default;
$material-color-deep-purple-a700: #6200ea !default;

$material-color-green-050:        #e8f5e9 !default;
$material-color-green-100:        #c8e6c9 !default;
$material-color-green-200:        #a5d6a7 !default;
$material-color-green-300:        #81c784 !default;
$material-color-green-400:        #66bb6a !default;
$material-color-green-500:        #4caf50 !default;
$material-color-green-600:        #43a047 !default;
$material-color-green-700:        #388e3c !default;
$material-color-green-800:        #2e7d32 !default;
$material-color-green-900:        #1b5e20 !default;
$material-color-green-a100:       #b9f6ca !default;
$material-color-green-a200:       #69f0ae !default;
$material-color-green-a400:       #00e676 !default;
$material-color-green-a700:       #00c853 !default;

$material-color-grey-050:         #fafafa !default;
$material-color-grey-100:         #f5f5f5 !default;
$material-color-grey-200:         #eeeeee !default;
$material-color-grey-300:         #e0e0e0 !default;
$material-color-grey-400:         #bdbdbd !default;
$material-color-grey-500:         #9e9e9e !default;
$material-color-grey-600:         #757575 !default;
$material-color-grey-700:         #616161 !default;
$material-color-grey-800:         #424242 !default;
$material-color-grey-900:         #212121 !default;

$material-color-indigo-050:       #e8eaf6 !default;
$material-color-indigo-100:       #c5cae9 !default;
$material-color-indigo-200:       #9fa8da !default;
$material-color-indigo-300:       #7986cb !default;
$material-color-indigo-400:       #5c6bc0 !default;
$material-color-indigo-500:       #3f51b5 !default;
$material-color-indigo-600:       #3949ab !default;
$material-color-indigo-700:       #303f9f !default;
$material-color-indigo-800:       #283593 !default;
$material-color-indigo-900:       #1a237e !default;
$material-color-indigo-a100:      #8c9eff !default;
$material-color-indigo-a200:      #536dfe !default;
$material-color-indigo-a400:      #3d5afe !default;
$material-color-indigo-a700:      #304ffe !default;

$material-color-light-blue-050:   #e1f5fe !default;
$material-color-light-blue-100:   #b3e5fc !default;
$material-color-light-blue-200:   #81d4fa !default;
$material-color-light-blue-300:   #4fc3f7 !default;
$material-color-light-blue-400:   #29b6f6 !default;
$material-color-light-blue-500:   #03a9f4 !default;
$material-color-light-blue-600:   #039be5 !default;
$material-color-light-blue-700:   #0288d1 !default;
$material-color-light-blue-800:   #0277bd !default;
$material-color-light-blue-900:   #01579b !default;
$material-color-light-blue-a100:  #80d8ff !default;
$material-color-light-blue-a200:  #40c4ff !default;
$material-color-light-blue-a400:  #00b0ff !default;
$material-color-light-blue-a700:  #0091ea !default;

$material-color-light-green-050:  #f1f8e9 !default;
$material-color-light-green-100:  #dcedc8 !default;
$material-color-light-green-200:  #c5e1a5 !default;
$material-color-light-green-300:  #aed581 !default;
$material-color-light-green-400:  #9ccc65 !default;
$material-color-light-green-500:  #8bc34a !default;
$material-color-light-green-600:  #7cb342 !default;
$material-color-light-green-700:  #689f38 !default;
$material-color-light-green-800:  #558b2f !default;
$material-color-light-green-900:  #33691e !default;
$material-color-light-green-a100: #ccff90 !default;
$material-color-light-green-a200: #b2ff59 !default;
$material-color-light-green-a400: #76ff03 !default;
$material-color-light-green-a700: #64dd17 !default;

$material-color-lime-050:         #f9fbe7 !default;
$material-color-lime-100:         #f0f4c3 !default;
$material-color-lime-200:         #e6ee9c !default;
$material-color-lime-300:         #dce775 !default;
$material-color-lime-400:         #d4e157 !default;
$material-color-lime-500:         #cddc39 !default;
$material-color-lime-600:         #c0ca33 !default;
$material-color-lime-700:         #afb42b !default;
$material-color-lime-800:         #9e9d24 !default;
$material-color-lime-900:         #827717 !default;
$material-color-lime-a100:        #f4ff81 !default;
$material-color-lime-a200:        #eeff41 !default;
$material-color-lime-a400:        #c6ff00 !default;
$material-color-lime-a700:        #aeea00 !default;

$material-color-orange-050:       #fff3e0 !default;
$material-color-orange-100:       #ffe0b2 !default;
$material-color-orange-200:       #ffcc80 !default;
$material-color-orange-300:       #ffb74d !default;
$material-color-orange-400:       #ffa726 !default;
$material-color-orange-500:       #ff9800 !default;
$material-color-orange-600:       #fb8c00 !default;
$material-color-orange-700:       #f57c00 !default;
$material-color-orange-800:       #ef6c00 !default;
$material-color-orange-900:       #e65100 !default;
$material-color-orange-a100:      #ffd180 !default;
$material-color-orange-a200:      #ffab40 !default;
$material-color-orange-a400:      #ff9100 !default;
$material-color-orange-a700:      #ff6d00 !default;

$material-color-pink-050:         #fce4ec !default;
$material-color-pink-100:         #f8bbd0 !default;
$material-color-pink-200:         #f48fb1 !default;
$material-color-pink-300:         #f06292 !default;
$material-color-pink-400:         #ec407a !default;
$material-color-pink-500:         #e91e63 !default;
$material-color-pink-600:         #d81b60 !default;
$material-color-pink-700:         #c2185b !default;
$material-color-pink-800:         #ad1457 !default;
$material-color-pink-900:         #880e4f !default;
$material-color-pink-a100:        #ff80ab !default;
$material-color-pink-a200:        #ff4081 !default;
$material-color-pink-a400:        #f50057 !default;
$material-color-pink-a700:        #c51162 !default;

$material-color-purple-050:       #f3e5f5 !default;
$material-color-purple-100:       #e1bee7 !default;
$material-color-purple-200:       #ce93d8 !default;
$material-color-purple-300:       #ba68c8 !default;
$material-color-purple-400:       #ab47bc !default;
$material-color-purple-500:       #9c27b0 !default;
$material-color-purple-600:       #8e24aa !default;
$material-color-purple-700:       #7b1fa2 !default;
$material-color-purple-800:       #6a1b9a !default;
$material-color-purple-900:       #4a148c !default;
$material-color-purple-a100:      #ea80fc !default;
$material-color-purple-a200:      #e040fb !default;
$material-color-purple-a400:      #d500f9 !default;
$material-color-purple-a700:      #aa00ff !default;

$material-color-red-050:          #ffebee !default;
$material-color-red-100:          #ffcdd2 !default;
$material-color-red-200:          #ef9a9a !default;
$material-color-red-300:          #e57373 !default;
$material-color-red-400:          #ef5350 !default;
$material-color-red-500:          #f44336 !default;
$material-color-red-600:          #e53935 !default;
$material-color-red-700:          #d32f2f !default;
$material-color-red-800:          #c62828 !default;
$material-color-red-900:          #b71c1c !default;
$material-color-red-a100:         #ff8a80 !default;
$material-color-red-a200:         #ff5252 !default;
$material-color-red-a400:         #ff1744 !default;
$material-color-red-a700:         #d50000 !default;

$material-color-teal-050:         #e0f2f1 !default;
$material-color-teal-100:         #b2dfdb !default;
$material-color-teal-200:         #80cbc4 !default;
$material-color-teal-300:         #4db6ac !default;
$material-color-teal-400:         #26a69a !default;
$material-color-teal-500:         #009688 !default;
$material-color-teal-600:         #00897b !default;
$material-color-teal-700:         #00796b !default;
$material-color-teal-800:         #00695c !default;
$material-color-teal-900:         #004d40 !default;
$material-color-teal-a100:        #a7ffeb !default;
$material-color-teal-a200:        #64ffda !default;
$material-color-teal-a400:        #1de9b6 !default;
$material-color-teal-a700:        #00bfa5 !default;

$material-color-yellow-050:       #fffde7 !default;
$material-color-yellow-100:       #fff9c4 !default;
$material-color-yellow-200:       #fff59d !default;
$material-color-yellow-300:       #fff176 !default;
$material-color-yellow-400:       #ffee58 !default;
$material-color-yellow-500:       #ffeb3b !default;
$material-color-yellow-600:       #fdd835 !default;
$material-color-yellow-700:       #fbc02d !default;
$material-color-yellow-800:       #f9a825 !default;
$material-color-yellow-900:       #f57f17 !default;
$material-color-yellow-a100:      #ffff8d !default;
$material-color-yellow-a200:      #ffff00 !default;
$material-color-yellow-a400:      #ffea00 !default;
$material-color-yellow-a700:      #ffd600 !default;

// stylelint-disable scss/dollar-variable-default
$ambers: () !default;
$ambers: map-merge(
  (
    50:   $material-color-amber-050,
    100:  $material-color-amber-100,
    200:  $material-color-amber-200,
    300:  $material-color-amber-300,
    400:  $material-color-amber-400,
    500:  $material-color-amber-500,
    600:  $material-color-amber-600,
    700:  $material-color-amber-700,
    800:  $material-color-amber-800,
    900:  $material-color-amber-900,
    a100: $material-color-amber-a100,
    a200: $material-color-amber-a200,
    a400: $material-color-amber-a400,
    a700: $material-color-amber-a700
  ),
  $ambers
);

$blues: () !default;
$blues: map-merge(
  (
    50:   $material-color-blue-050,
    100:  $material-color-blue-100,
    200:  $material-color-blue-200,
    300:  $material-color-blue-300,
    400:  $material-color-blue-400,
    500:  $material-color-blue-500,
    600:  $material-color-blue-600,
    700:  $material-color-blue-700,
    800:  $material-color-blue-800,
    900:  $material-color-blue-900,
    a100: $material-color-blue-a100,
    a200: $material-color-blue-a200,
    a400: $material-color-blue-a400,
    a700: $material-color-blue-a700
  ),
  $blues
);

$blue-greys: () !default;
$blue-greys: map-merge(
  (
    50:   $material-color-blue-grey-050,
    100:  $material-color-blue-grey-100,
    200:  $material-color-blue-grey-200,
    300:  $material-color-blue-grey-300,
    400:  $material-color-blue-grey-400,
    500:  $material-color-blue-grey-500,
    600:  $material-color-blue-grey-600,
    700:  $material-color-blue-grey-700,
    800:  $material-color-blue-grey-800,
    900:  $material-color-blue-grey-900
  ),
  $blue-greys
);

$browns: () !default;
$browns: map-merge(
  (
    50:   $material-color-brown-050,
    100:  $material-color-brown-100,
    200:  $material-color-brown-200,
    300:  $material-color-brown-300,
    400:  $material-color-brown-400,
    500:  $material-color-brown-500,
    600:  $material-color-brown-600,
    700:  $material-color-brown-700,
    800:  $material-color-brown-800,
    900:  $material-color-brown-900
  ),
  $browns
);

$cyans: () !default;
$cyans: map-merge(
  (
    50:   $material-color-cyan-050,
    100:  $material-color-cyan-100,
    200:  $material-color-cyan-200,
    300:  $material-color-cyan-300,
    400:  $material-color-cyan-400,
    500:  $material-color-cyan-500,
    600:  $material-color-cyan-600,
    700:  $material-color-cyan-700,
    800:  $material-color-cyan-800,
    900:  $material-color-cyan-900,
    a100: $material-color-cyan-a100,
    a200: $material-color-cyan-a200,
    a400: $material-color-cyan-a400,
    a700: $material-color-cyan-a700
  ),
  $cyans
);

$deep-oranges: () !default;
$deep-oranges: map-merge(
  (
    50:   $material-color-deep-orange-050,
    100:  $material-color-deep-orange-100,
    200:  $material-color-deep-orange-200,
    300:  $material-color-deep-orange-300,
    400:  $material-color-deep-orange-400,
    500:  $material-color-deep-orange-500,
    600:  $material-color-deep-orange-600,
    700:  $material-color-deep-orange-700,
    800:  $material-color-deep-orange-800,
    900:  $material-color-deep-orange-900,
    a100: $material-color-deep-orange-a100,
    a200: $material-color-deep-orange-a200,
    a400: $material-color-deep-orange-a400,
    a700: $material-color-deep-orange-a700
  ),
  $deep-oranges
);

$deep-purples: () !default;
$deep-purples: map-merge(
  (
    50:   $material-color-deep-purple-050,
    100:  $material-color-deep-purple-100,
    200:  $material-color-deep-purple-200,
    300:  $material-color-deep-purple-300,
    400:  $material-color-deep-purple-400,
    500:  $material-color-deep-purple-500,
    600:  $material-color-deep-purple-600,
    700:  $material-color-deep-purple-700,
    800:  $material-color-deep-purple-800,
    900:  $material-color-deep-purple-900,
    a100: $material-color-deep-purple-a100,
    a200: $material-color-deep-purple-a200,
    a400: $material-color-deep-purple-a400,
    a700: $material-color-deep-purple-a700
  ),
  $deep-purples
);

$greens: () !default;
$greens: map-merge(
  (
    50:   $material-color-green-050,
    100:  $material-color-green-100,
    200:  $material-color-green-200,
    300:  $material-color-green-300,
    400:  $material-color-green-400,
    500:  $material-color-green-500,
    600:  $material-color-green-600,
    700:  $material-color-green-700,
    800:  $material-color-green-800,
    900:  $material-color-green-900,
    a100: $material-color-green-a100,
    a200: $material-color-green-a200,
    a400: $material-color-green-a400,
    a700: $material-color-green-a700
  ),
  $greens
);

$greys: () !default;
$greys: map-merge(
  (
    50:   $material-color-grey-050,
    100:  $material-color-grey-100,
    200:  $material-color-grey-200,
    300:  $material-color-grey-300,
    400:  $material-color-grey-400,
    500:  $material-color-grey-500,
    600:  $material-color-grey-600,
    700:  $material-color-grey-700,
    800:  $material-color-grey-800,
    900:  $material-color-grey-900
  ),
  $greys
);

$indigos: () !default;
$indigos: map-merge(
  (
    50:   $material-color-indigo-050,
    100:  $material-color-indigo-100,
    200:  $material-color-indigo-200,
    300:  $material-color-indigo-300,
    400:  $material-color-indigo-400,
    500:  $material-color-indigo-500,
    600:  $material-color-indigo-600,
    700:  $material-color-indigo-700,
    800:  $material-color-indigo-800,
    900:  $material-color-indigo-900,
    a100: $material-color-indigo-a100,
    a200: $material-color-indigo-a200,
    a400: $material-color-indigo-a400,
    a700: $material-color-indigo-a700
  ),
  $indigos
);

$light-blues: () !default;
$light-blues: map-merge(
  (
    50:   $material-color-light-blue-050,
    100:  $material-color-light-blue-100,
    200:  $material-color-light-blue-200,
    300:  $material-color-light-blue-300,
    400:  $material-color-light-blue-400,
    500:  $material-color-light-blue-500,
    600:  $material-color-light-blue-600,
    700:  $material-color-light-blue-700,
    800:  $material-color-light-blue-800,
    900:  $material-color-light-blue-900,
    a100: $material-color-light-blue-a100,
    a200: $material-color-light-blue-a200,
    a400: $material-color-light-blue-a400,
    a700: $material-color-light-blue-a700
  ),
  $light-blues
);

$light-greens: () !default;
$light-greens: map-merge(
  (
    50:   $material-color-light-green-050,
    100:  $material-color-light-green-100,
    200:  $material-color-light-green-200,
    300:  $material-color-light-green-300,
    400:  $material-color-light-green-400,
    500:  $material-color-light-green-500,
    600:  $material-color-light-green-600,
    700:  $material-color-light-green-700,
    800:  $material-color-light-green-800,
    900:  $material-color-light-green-900,
    a100: $material-color-light-green-a100,
    a200: $material-color-light-green-a200,
    a400: $material-color-light-green-a400,
    a700: $material-color-light-green-a700
  ),
  $light-greens
);

$limes: () !default;
$limes: map-merge(
  (
    50:   $material-color-lime-050,
    100:  $material-color-lime-100,
    200:  $material-color-lime-200,
    300:  $material-color-lime-300,
    400:  $material-color-lime-400,
    500:  $material-color-lime-500,
    600:  $material-color-lime-600,
    700:  $material-color-lime-700,
    800:  $material-color-lime-800,
    900:  $material-color-lime-900,
    a100: $material-color-lime-a100,
    a200: $material-color-lime-a200,
    a400: $material-color-lime-a400,
    a700: $material-color-lime-a700
  ),
  $limes
);

$oranges: () !default;
$oranges: map-merge(
  (
    50:   $material-color-orange-050,
    100:  $material-color-orange-100,
    200:  $material-color-orange-200,
    300:  $material-color-orange-300,
    400:  $material-color-orange-400,
    500:  $material-color-orange-500,
    600:  $material-color-orange-600,
    700:  $material-color-orange-700,
    800:  $material-color-orange-800,
    900:  $material-color-orange-900,
    a100: $material-color-orange-a100,
    a200: $material-color-orange-a200,
    a400: $material-color-orange-a400,
    a700: $material-color-orange-a700
  ),
  $oranges
);

$pinks: () !default;
$pinks: map-merge(
  (
    50:   $material-color-pink-050,
    100:  $material-color-pink-100,
    200:  $material-color-pink-200,
    300:  $material-color-pink-300,
    400:  $material-color-pink-400,
    500:  $material-color-pink-500,
    600:  $material-color-pink-600,
    700:  $material-color-pink-700,
    800:  $material-color-pink-800,
    900:  $material-color-pink-900,
    a100: $material-color-pink-a100,
    a200: $material-color-pink-a200,
    a400: $material-color-pink-a400,
    a700: $material-color-pink-a700
  ),
  $pinks
);

$purples: () !default;
$purples: map-merge(
  (
    50:   $material-color-purple-050,
    100:  $material-color-purple-100,
    200:  $material-color-purple-200,
    300:  $material-color-purple-300,
    400:  $material-color-purple-400,
    500:  $material-color-purple-500,
    600:  $material-color-purple-600,
    700:  $material-color-purple-700,
    800:  $material-color-purple-800,
    900:  $material-color-purple-900,
    a100: $material-color-purple-a100,
    a200: $material-color-purple-a200,
    a400: $material-color-purple-a400,
    a700: $material-color-purple-a700
  ),
  $purples
);

$reds: () !default;
$reds: map-merge(
  (
    50:   $material-color-red-050,
    100:  $material-color-red-100,
    200:  $material-color-red-200,
    300:  $material-color-red-300,
    400:  $material-color-red-400,
    500:  $material-color-red-500,
    600:  $material-color-red-600,
    700:  $material-color-red-700,
    800:  $material-color-red-800,
    900:  $material-color-red-900,
    a100: $material-color-red-a100,
    a200: $material-color-red-a200,
    a400: $material-color-red-a400,
    a700: $material-color-red-a700
  ),
  $reds
);

$teals: () !default;
$teals: map-merge(
  (
    50:   $material-color-teal-050,
    100:  $material-color-teal-100,
    200:  $material-color-teal-200,
    300:  $material-color-teal-300,
    400:  $material-color-teal-400,
    500:  $material-color-teal-500,
    600:  $material-color-teal-600,
    700:  $material-color-teal-700,
    800:  $material-color-teal-800,
    900:  $material-color-teal-900,
    a100: $material-color-teal-a100,
    a200: $material-color-teal-a200,
    a400: $material-color-teal-a400,
    a700: $material-color-teal-a700
  ),
  $teals
);

$yellows: () !default;
$yellows: map-merge(
  (
    50:   $material-color-yellow-050,
    100:  $material-color-yellow-100,
    200:  $material-color-yellow-200,
    300:  $material-color-yellow-300,
    400:  $material-color-yellow-400,
    500:  $material-color-yellow-500,
    600:  $material-color-yellow-600,
    700:  $material-color-yellow-700,
    800:  $material-color-yellow-800,
    900:  $material-color-yellow-900,
    a100: $material-color-yellow-a100,
    a200: $material-color-yellow-a200,
    a400: $material-color-yellow-a400,
    a700: $material-color-yellow-a700
  ),
  $yellows
);

$colors: () !default;
$colors: map-merge(
  (
    'amber':       $material-color-amber-500,
    'blue':        $material-color-blue-500,
    'blue-grey':   $material-color-blue-grey-500,
    'brown':       $material-color-brown-500,
    'cyan':        $material-color-cyan-500,
    'deep-orange': $material-color-deep-orange-500,
    'deep-purple': $material-color-deep-purple-500,
    'green':       $material-color-green-500,
    'grey':        $material-color-grey-500,
    'indigo':      $material-color-indigo-500,
    'light-blue':  $material-color-light-blue-500,
    'light-green': $material-color-light-green-500,
    'lime':        $material-color-lime-500,
    'orange':      $material-color-orange-500,
    'pink':        $material-color-pink-500,
    'purple':      $material-color-purple-500,
    'red':         $material-color-red-500,
    'teal':        $material-color-teal-500,
    'yellow':      $material-color-yellow-500
  ),
  $colors
);
// stylelint-enable

// Black and white
// Based on https://material.google.com/style/color.html#color-text-background-colors

$black:                   #000000 !default;

$black-primary-opacity:   0.87 !default;
$black-secondary-opacity: 0.54 !default;
$black-hint-opacity:      0.38 !default;
$black-divider-opacity:   0.12 !default;

$black-primary:           rgba(red($black), green($black), blue($black), $black-primary-opacity) !default;
$black-secondary:         rgba(red($black), green($black), blue($black), $black-secondary-opacity) !default;
$black-hint:              rgba(red($black), green($black), blue($black), $black-hint-opacity) !default;
$black-divider:           rgba(red($black), green($black), blue($black), $black-divider-opacity) !default;

$white:                   #ffffff !default;

$white-primary-opacity:   1 !default;
$white-secondary-opacity: 0.7 !default;
$white-hint-opacity:      0.5 !default;
$white-divider-opacity:   0.12 !default;

$white-primary:           rgba(red($white), green($white), blue($white), $white-primary-opacity) !default;
$white-secondary:         rgba(red($white), green($white), blue($white), $white-secondary-opacity) !default;
$white-hint:              rgba(red($white), green($white), blue($white), $white-hint-opacity) !default;
$white-divider:           rgba(red($white), green($white), blue($white), $white-divider-opacity) !default;

// Theme
// Based on https://material.google.com/style/color.html#color-themes

$dark-theme-1:  #000000 !default;
$dark-theme-2:  $material-color-grey-900 !default;
$dark-theme-3:  #303030 !default;
$dark-theme-4:  $material-color-grey-800 !default;

$light-theme-1: $material-color-grey-300 !default;
$light-theme-2: $material-color-grey-100 !default;
$light-theme-3: $material-color-grey-050 !default;
$light-theme-4: #ffffff !default;
