.nav-tabs {
  box-shadow: inset 0 ($nav-tab-border-width * -2) 0 ($nav-tab-border-width * -1) $nav-tab-border-color;

  &.border-0,
  &.border-bottom-0 {
    box-shadow: none;
  }

  .nav-link {
    @include transition-standard(background-color, color, opacity);

    color: $nav-tab-color;
    font-size: $nav-tab-font-size;
    font-weight: $nav-tab-font-weight;
    line-height: $nav-tab-line-height;
    min-height: $nav-tab-height;
    opacity: $nav-tab-link-opacity;
    padding: $nav-tab-link-padding-y $nav-tab-link-padding-x;
    position: relative;
    text-transform: uppercase;

    @include active-focus-hover {
      background-color: $nav-tab-bg-hover;
    }

    &.active,
    &:active {
      opacity: 1;
    }

    &.active {
      color: $nav-tab-color-active;

      &::before {
        opacity: 1;
      }
    }

    &.disabled {
      background-color: transparent;
      color: $nav-tab-color-disabled;
      opacity: 1;
    }

    &::before {
      @include transition-standard(opacity);

      background-color: $nav-tab-indicator-bg;
      content: '';
      display: block;
      height: $nav-tab-indicator-height;
      opacity: 0;
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }

  .nav-item.show .nav-link {
    background-color: $nav-tab-bg-hover;
    opacity: 1;
  }
}

.nav-tabs-material {
  position: relative;

  &.animate {
    .nav-link::before {
      opacity: 0;
    }

    .nav-tabs-indicator {
      @include transition-standard(left, right);
    }
  }

  .nav-link::before {
    transition: none;
  }

  .nav-tabs-indicator {
    background-color: $nav-tab-indicator-bg;
    display: none;
    height: $nav-tab-indicator-height;
    position: absolute;
    bottom: 0;

    &.show {
      display: block;
    }

    .nav-tabs-scrollable & {
      bottom: $nav-tab-height;
    }
  }
}

.nav-tabs-scrollable {
  box-shadow: inset 0 ($nav-tab-border-width * -2) 0 ($nav-tab-border-width * -1) $nav-tab-border-color;
  height: $nav-tab-height;
  overflow: hidden;

  .nav-tabs {
    box-shadow: none;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    padding-bottom: $nav-tab-height;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}
