// stylelint-disable declaration-no-important

.shadow {
  box-shadow: map-get($shadows, 8) !important;
}

.shadow-lg {
  box-shadow: map-get($shadows, 24) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.shadow-sm {
  box-shadow: map-get($shadows, 2) !important;
}

@each $shadow, $value in $shadows {
  .shadow-#{$shadow} {
    box-shadow: $value !important;
  }
}
